export const oesteAcompanhamentoLives = {
  title: "Acompanhamento Lives",
  tabs: {
    operation: {
      label: "Lives Cadastradas",
      title: "Lives Cadastradas",
      description: "Acompanhamento.",
      cleanOption: true,
      downloadOption: false,
      columns: [
        { label: "Nome", column: "name" },
        { label: "Playlist ID", column: "playlistId" },
        { label: "Criado em", column: "createdAt", format: "date" }
      ],
      actions: {
        run: {
          active: false,
          label: "Executar"
        },
        unitaryAction: {
          active: true,
          label: "Renovação unitária"
        }
      }
    },
    feature1: {
      active: true,
      label: "Lives Agendadas",
      title: "Últimos registros",
      description: "Confira as últimas atualizações dos registros.",
      cleanOption: false,
      downloadOption: false,
      columns: [
        {
          label: "Início Live",
          column: "initLive",
          format: "date"
        },
        {
          label: "Fim Live",
          column: "endLive",
          format: "date"
        },
        { label: "Planilha", column: "sheet", align: "left" },
        { label: "Live ID", column: "platformLiveId", align: "left" },
        { label: "Letra Inicial", column: "initCellLetter" },
        { label: "Número Inicial", column: "initCellNumber" }
      ],
      actions: {
        run: {
          active: true,
          label: "Executar"
        },
        unitaryAction: {
          active: false,
          label: "Renovação unitária"
        },
        addLive: {
          active: true,
          label: "Adicionar Live"
        },
        uploader: {
          active: false,
          label: "Upload arquivos CSV"
        }
      }
    }
  }
};
