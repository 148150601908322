export function showWhenAnyOf(routes) {
  return function (location) {
    return routes.some((route) => location.pathname === route);
  };
}

export const showWhenPrefix = (routes) => {
  return routes.some((route) => location.pathname.startsWith(route));
};

export function showExcept(routes) {
  return function (location) {
    return routes.every((route) => location.pathname !== route);
  };
}
