export const oesteVindiFaturas = {
    title: "Vindi - Faturas",
    tabs: {
      operation: {
        label: "Histórico Cancelamentos",
        title: "Histórico Cancelamentos",
        description: "Últimos cancelamento efetuados.",
        cleanOption: true,
        downloadOption: true,
        columns: [
          { label: "Cancelamentos Totais", column: "renovacoes_totais" },
          { label: "Sucesso", column: "sucesso" },
          { label: "Erro", column: "error" },
          {
            label: "Início Cancelamento",
            column: "inicio_renovacao",
            format: "date"
          },
          { label: "Fim Cancelamento", column: "fim_renovacao", format: "date" },
          { label: "Status", column: "status" }
          ],
          actions:{
              run: {
                  active: false,
                  label: "Executar"
              },
          }
        },
        feature1: {
          active: true,
          label: "Últimos registros",
          title: "Últimos registros",
          description: "Confira as últimas atualizações dos registros.",
          cleanOption: false,
          downloadOption: false,
          columns: [
            { label: "Nome", column: "nome", align: "left" },
            { label: "Email", column: "email", align: "left" },
            { label: "Assinatura", column: "assinatura" },
            { label: "Fatura", column: "fatura" },
            { label: "Valor", column: "valor" },
            { label: "Status", column: "status" },
            {
              label: "Adicionado em",
              column: "createdAt",
              format: "date"
            },
            {
              label: "Resposta Vindi",
              column: "resposta_pagar",
              renderCell: "status"
            }
          ],
          actions: {
            run: {
              active: true,
              label: "Executar"
            },
            unitaryAction: {
              active: false,
              label: "Cancelamento unitário"
            },
            uploader: {
              active: true,
              label: "Upload arquivos CSV"
            }
          }
        }
      }
    };
  