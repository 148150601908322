export const renewVindiOeste = {
  title: "Vindi - Renovação Assinatura",
  tabs: {
    operation: {
      label: "Resumo de Operações",
      title: "Resumo de Operações",
      description: "Últimas operações realizadas no sistema.",
      cleanOption: true,
      downloadOption: true,
      columns: [
        { label: "Renovações Totais", column: "renovacoes_totais" },
        { label: "Sucesso", column: "sucesso" },
        { label: "Erro", column: "error" },
        {
          label: "Início Renovação",
          column: "inicio_renovacao",
          format: "date"
        },
        { label: "Fim Renovação", column: "fim_renovacao", format: "date" },
        { label: "Status", column: "status" }
      ],
      actions:{
          run: {
              active: false,
              label: "Executar"
          },
      }
    },
    feature1: {
      active: true,
      label: "Renovação",
      title: "Inserir renovação",
      cleanOption: true,
      downloadOption: true,
      description:
        "Insira dados na tabela via arquivo CSV ou adição unitária de renovação.",
      columns: [
        { label: "Nome", column: "nome", align: "left" },
        { label: "Email", column: "email", align: "left" },
        { label: "Assinatura Atual", column: "assinatura" },
        { label: "Status", column: "status" },
        {
          label: "Adicionado em",
          column: "createdAt",
          format: "date"
        },
        {
          label: "Resposta Vindi",
          column: "resposta_pagar",
          renderCell: "status"
        }
      ],
      actions: {
        run: {
          active: true,
          label: "Executar"
        },
        unitaryAction: {
          active: false,
          label: "Renovação unitária"
        },
        uploader: {
          active: true,
          label: "Upload arquivos CSV"
        }
      }
    }
  }
};
