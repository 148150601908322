import { registerApplication, start } from "single-spa";
import { showWhenPrefix } from "./RoutingUtils";
import { cancellationOeste } from "./rpaConfigs/uploaders/cancellation-oeste";
import { renewOeste } from "./rpaConfigs/uploaders/renew-oeste";
import { renewVindiReport } from "./rpaConfigs/uploaders/renew-vindi-report";
import { oesteVindiReativacao } from "./rpaConfigs/uploaders/oeste-vindi-reativacao";
import { oesteVindiCancelamento } from "./rpaConfigs/uploaders/oeste-vindi-cancelamento";
import { renewVindiOeste } from "./rpaConfigs/uploaders/renew-vindi-oeste";
import { rpaCadastro } from "./rpaConfigs/schedulers/rpa-cadastro";
import { rpaConciliacao } from "./rpaConfigs/schedulers/rpa-conciliacao";
import { oesteVindiAtualizacao } from "./rpaConfigs/uploaders/oeste-vindi-atualizacao";
import { oesteVindiFaturas } from "./rpaConfigs/uploaders/oeste-vindi-faturas";
import { oestePagarmeReport } from "./rpaConfigs/uploaders/oeste-pagarme-report";
import { oesteVindiNovaAssinatura } from "./rpaConfigs/uploaders/oeste-vindi-nova-assinatura";
import { oesteLinkPagamento } from "./rpaConfigs/uploaders/oeste-link-pagamento";
import { oesteVindiExtract } from "./rpaConfigs/uploaders/oeste-vindi-extract";
import { oesteCarrinhoAbandonado } from "./rpaConfigs/uploaders/oeste-carrinho-abandonado";
import { oesteAcompanhamentoLives } from "./rpaConfigs/uploaders/oeste-lives";
import { oesteVindiAtualizacaoFatura } from "./rpaConfigs/uploaders/oeste-vindi-atualizacao-fatura";
import { oesteConciliacao } from "./rpaConfigs/uploaders/oeste-conciliacao";
import { oesteVindiGcp } from "./rpaConfigs/uploaders/oeste-vindi-gcp";

registerApplication({
  name: "@intraversa/portico-react",
  app: () => System.import("@intraversa/portico-react"),
  activeWhen: () =>
    showWhenPrefix(["/home", "/forgotPassword", "/profile", "/user-profile"]) ||
    location.pathname === "/"
});

registerApplication({
  name: "@intraversa/portico-scheduled",
  app: () => System.import("@intraversa/portico-scheduled"),
  activeWhen: () => {
    return showWhenPrefix([
      "viewb-",
      "/viewb-register",
      "/viewb-conciliation",
      "/viewb-cancellation",
      "/elev-bitrix-omie"
    ]);
  },
  customProps: () => {
    const configs = {
      "viewb-register": rpaCadastro,
      "viewb-conciliation": rpaConciliacao,
      "viewb-cancellation": rpaConciliacao,
      "elev-bitrix-omie": rpaConciliacao
    };

    const config = Object.keys(configs).find((key) => {
      const routePrefix = location.pathname.split("/")?.[1];
      const findConfigWhenURLEqualsKey = routePrefix === key;
      return findConfigWhenURLEqualsKey;
    });

    return {
      data: configs[config]
    };
  }
  //
  //
  //
});

registerApplication({
  name: "@intraversa/portico-uploader",
  app: () => System.import("@intraversa/portico-uploader"),
  activeWhen: () => {
    return showWhenPrefix([
      "/oeste-",
      "/oeste-vindi-subscription",
      "/oeste-subscription",
      "/oeste-cancellation",
      "/oeste-vindi-report",
      "/oeste-vindi-cancelamento",
      "/oeste-vindi-reativacao",
      "/oeste-vindi-atualizacao",
      "/oeste-vindi-faturas",
      "/oeste-pagarme-reports",
      "/oeste-vindi-novas-assinaturas",
      "/oeste-link-pagamento",
      "/oeste-vindi-extract",
      "/oeste-carrinho-abandonado",
      "/oeste-lives",
      "/oeste-vindi-atualizacao-fatura",
      "/oeste-conciliacao",
      "/oeste-vindi-gcp"
    ]);
  },
  customProps: () => {
    const configs = {
      "oeste-cancellation": cancellationOeste,
      "oeste-subscription": renewOeste,
      "oeste-vindi-subscription": renewVindiOeste,
      "oeste-vindi-report": renewVindiReport,
      "oeste-vindi-cancelamento": oesteVindiCancelamento,
      "oeste-vindi-reativacao": oesteVindiReativacao,
      "oeste-vindi-atualizacao": oesteVindiAtualizacao,
      "oeste-vindi-faturas": oesteVindiFaturas,
      "oeste-pagarme-reports": oestePagarmeReport,
      "oeste-vindi-novas-assinaturas": oesteVindiNovaAssinatura,
      "oeste-link-pagamento": oesteLinkPagamento,
      "oeste-vindi-extract": oesteVindiExtract,
      "oeste-carrinho-abandonado": oesteCarrinhoAbandonado,
      "oeste-lives": oesteAcompanhamentoLives,
      "oeste-vindi-atualizacao-fatura": oesteVindiAtualizacaoFatura,
      "oeste-conciliacao": oesteConciliacao,
      "oeste-vindi-gcp": oesteVindiGcp
    };
    const config = Object.keys(configs).find((key) => {
      const routePrefix = location.pathname.split("/")?.[1];
      const findConfigWhenURLEqualsKey = routePrefix === key;
      return findConfigWhenURLEqualsKey;
    });

    return {
      data: configs[config]
    };
  }
});

start();
