export const oesteVindiGcp = {
  title: "Envio Vindi - Google (Api)",
  tabs: {
    operation: {
      label: "Histórico envios",
      title: "Histórico envios",
      description: "Últimos envios gerados.",
      cleanOption: true,
      downloadOption: true,
      columns: [
        { label: "Registros Totais", column: "renovacoes_totais" },
        { label: "Arquivos enviados", column: "sucesso" },
        { label: "Arquivos com erro", column: "error" },
        {
          label: "Início Report",
          column: "inicio_renovacao",
          format: "date"
        },
        { label: "Fim Report", column: "fim_renovacao", format: "date" },
        { label: "Status", column: "status" }
      ],
      actions: {
        run: {
          active: false,
          label: "Executar"
        }
      }
    },
    feature1: {
      active: true,
      label: "Últimos registros",
      title: "Últimos registros",
      description: "Confira as últimas atualizações dos registros.",
      cleanOption: false,
      downloadOption: true,
      columns: [
        { label: "Id objeto", column: "objectId", align: "left" },
        { label: "Nome objeto", column: "objectType", align: "left" },
        { label: "Recolhido em", column: "createdAt" },
        { label: "Objeto criado em", column: "objectCreatedAt" },
        { label: "Objeto atualizado em", column: "objectUpdatedAt" },
        { label: "Enviado ao GCP em", column: "gcpSentAt" }
      ],
      actions: {
        run: {
          active: true,
          label: "Executar"
        },
        unitaryAction: {
          active: false,
          label: "Renovação unitários"
        },
        uploader: {
          active: false,
          label: "Upload arquivos CSV"
        }
      }
    }
  }
};
