export const rpaCadastro = {
    title: 'RPA Cadastro',
    tabs: {
        integration: {
            columnsExecutions: [
                {
                  label: 'Horário',
                  column: 'createdAt',
                  align: 'left',
                  format: 'date',
                },
                {label: 'Nº Usuários Cadastrados', column: 'success'},
                {label: 'Erros', column: 'errors'},
                {
                  label: 'Execução Manual',
                  column: 'manual',
                  format: 'boolean',
                },
            ],
            columnByStep: [
                //step 1, 2, 3
                {label: 'Usuário', column: 'name', align: 'left', format: 'checkEmpty', active: ['1', '2', '3']},
                {label: 'Email', column: 'email', format: 'checkEmpty', active: ['1', '2', '3']},
                {label: 'Telefone', column: 'phone', format: 'checkEmpty', active: ['1', '2', '3']},
                // step 4
                {
                    label: 'Cliente',
                    column: 'portal.client',
                    align: 'left',
                    format: 'checkEmpty',
                    active: ['4']
                  },
                  {
                    label: 'Usuário',
                    column: 'portal.user',
                    align: 'left',
                    format: 'checkEmpty',
                    active: ['4']
                  },
                  {label: 'Senha', column: 'portal.password', format: 'checkEmpty',  active: ['4']},
                  {label: 'Projeto', column: 'portal.project', format: 'checkEmpty',  active: ['4']},
                // step 5
                {label: 'Email', column: 'email', align: 'left', format: 'checkEmpty',   active: ['5']},
                {
                  label: 'Status do Envio',
                  column: 'emailSender.success',
                  align: 'left',
                  renderCell: 'status',
                  active: ['5']
                },
                {label: 'Erro', column: 'error', format: 'checkEmpty',  active: ['5']},
            ]
        }
    }
}