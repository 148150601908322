export const oesteVindiNovaAssinatura = {
  title: "Vindi - Novas assinaturas",
  tabs: {
    operation: {
      label: "Histórico criação",
      title: "Histórico criação",
      description: "Últimos assinaturas criadas.",
      cleanOption: true,
      downloadOption: true,
      columns: [
        { label: "Registros Totais", column: "renovacoes_totais" },
        { label: "Sucesso", column: "sucesso" },
        { label: "Erro", column: "error" },
        {
          label: "Início Criação",
          column: "inicio_renovacao",
          format: "date"
        },
        { label: "Fim Criação", column: "fim_renovacao", format: "date" },
        { label: "Status", column: "status" }
      ],
      actions: {
        run: {
          active: false,
          label: "Executar"
        }
      }
    },
    feature1: {
      active: true,
      label: "Últimos registros",
      title: "Últimos registros",
      description: "Confira as últimas atualizações dos registros.",
      cleanOption: true,
      downloadOption: false,
      columns: [
        { label: "Plano", column: "planId", align: "left" },
        { label: "Código Plano", column: "planCode", align: "left" },
        { label: "Nome Plano", column: "planName", align: "left" },
        { label: "Método Pagamento", column: "paymentMethod", align: "left" },
        { label: "Código Cliente", column: "customerId", align: "left" },
        { label: "Cliente", column: "customerName", align: "left" },
        { label: "Email Cliente", column: "customerEmail", align: "left" },
        { label: "ID Assinatura", column: "subscriptionId", align: "left" },
        { label: "Status Wordpress", column: "novoWp", align: "left" },
        {
          label: "Resposta Vindi / Wordpress",
          column: "status_code_api",
          renderCell: "status"
        },
        {
          label: "Status",
          column: "statusCodeApi",
          renderCell: "resposta_pagar"
        }
      ],
      actions: {
        run: {
          active: true,
          label: "Executar"
        },
        unitaryAction: {
          active: false,
          label: "Renovação unitária"
        },
        addSubscription: {
          active: true,
          label: "Adicionar Assinatura"
        },
        uploader: {
          active: true,
          label: "Upload arquivos CSV"
        }
      }
    }
  }
};
