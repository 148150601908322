export const rpaConciliacao = {
    title: 'RPA Conciliação',
    tabs: {
        integration: {
            columnsExecutions: [
              {
                label: 'Horário',
                column: 'createdAt',
                align: 'left',
                format: 'date',
              },
              {label: 'Nº Contratos Cadastrados', column: 'success'},
              {label: 'Erros', column: 'errors'},
              {
                label: 'Execução Manual',
                column: 'manual',
                format: 'boolean',
              },
            ],
            columnByStep: [
                //step 1, 2
                {
                  label: 'Empresa',
                  column: 'company',
                  align: 'left',
                  format: 'checkEmpty',
                  active: ['1', '2']
                },
                {label: 'CNPJ', column: 'document', format: 'checkEmpty', active: ['1', '2']},
                {label: 'Plano', column: 'plan', format: 'checkEmpty', active: ['1', '2']},
                // step 3
                {
                  label: 'Empresa',
                  column: 'company',
                  align: 'left',
                  format: 'checkEmpty',
                  active: ['3']
                },
                {label: 'CNPJ', column: 'document', format: 'checkEmpty', active: ['3']},
                {label: 'Plano', column: 'plan', format: 'checkEmpty', active: ['3']},
                {
                  label: 'Vendas Criadas',
                  column: 'contaAzulSales',
                  format: 'checkEmpty',
                  active: ['3']
                },
                // step 4
                {
                  label: 'Cliente',
                  column: 'portal.client',
                  align: 'left',
                  format: 'checkEmpty',
                  active: ['4']
                },
                {
                  label: 'Usuário',
                  column: 'portal.user',
                  align: 'left',
                  format: 'checkEmpty',
                  active: ['4']
                },
                {label: 'Senha', column: 'portal.password', format: 'checkEmpty', active: ['4']},
                {label: 'Projeto', column: 'portal.project', format: 'checkEmpty', active: ['4']},
            ]
        }
    }
}