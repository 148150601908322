export const oesteConciliacao = {
  title: "Conciliação Vindi - Google (Api)",
  tabs: {
    operation: {
      label: "Histórico conciliações",
      title: "Histórico conciliações",
      description: "Últimas conciliações geradas.",
      cleanOption: true,
      downloadOption: true,
      columns: [
        {
          label: "Início Conciliação",
          column: "inicio_renovacao",
          format: "date"
        },
        { label: "Fim Conciliação", column: "fim_renovacao", format: "date" },
        { label: "Status", column: "status" }
      ],
      actions: {
        run: {
          active: false,
          label: "Executar"
        }
      }
    },
    feature1: {
      active: true,
      label: "Últimos registros",
      title: "Últimos registros",
      description: "Confira as últimas atualizações dos registros.",
      cleanOption: false,
      downloadOption: false,
      columns: [
        { label: "Tipo objeto", column: "objectType", align: "left" },
        { label: "Conciliados", column: "quantity", align: "left" },
        { label: "Data", column: "createdAt", align: "left" }
      ],
      actions: {
        run: {
          active: true,
          label: "Executar"
        },
        unitaryAction: {
          active: false,
          label: "Renovação unitár"
        },
        uploader: {
          active: false,
          label: "Upload arquivos CSV"
        }
      }
    }
  }
};
