export const oesteLinkPagamento = {
  title: "Oeste - Link de Pagamento para Inadimplentes",
  tabs: {
    operation: {
      label: "Histórico reports",
      title: "Histórico reports",
      description: "Últimos reports gerados.",
      cleanOption: true,
      downloadOption: true,
      columns: [
        { label: "Registros Totais", column: "renovacoes_totais" },
        { label: "Sucesso", column: "sucesso" },
        { label: "Erro", column: "error" },
        {
          label: "Início Report",
          column: "inicio_renovacao",
          format: "date"
        },
        { label: "Fim Report", column: "fim_renovacao", format: "date" },
        { label: "Status", column: "status" }
      ],
      actions: {
        run: {
          active: true,
          label: "Executar"
        }
      }
    },
    feature1: {
      active: false,
      label: "Últimos registros",
      title: "Últimos registros",
      description: "Confira as últimas atualizações dos registros.",
      cleanOption: false,
      downloadOption: false,
      columns: [
        { label: "ID Assinatura", column: "idAssinatura", align: "left" },
        { label: "Cliente", column: "nomeCliente", align: "left" },
        { label: "Plano", column: "plano" },
        { label: "Data Criação", column: "dataDeCriacao" },
        {
          label: "Status Assinatura",
          column: "status"
        },
        { label: "Inicio Assinatura", column: "inicioPeriodo", format: "date" },
        { label: "Fim Assinatura", column: "fimPeriodo" }
      ],
      actions: {
        run: {
          active: true,
          label: "Executar"
        },
        unitaryAction: {
          active: false,
          label: "Renovação unitária"
        },
        uploader: {
          active: false,
          label: "Upload arquivos CSV"
        }
      }
    }
  }
};
